import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import * as Yup from 'yup';

const description: FormInput = {
  type: 'textarea',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'description',
    label: 'Fault:description',
  },
};

const photo: FormInput = {
  type: 'file',
  initValue: null,
  props: {
    name: 'photo',
    label: 'Fault:photo',
  },
};

const vehicleId: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    placeholder: 'Fault:vehicleIdPlaceholder',
    name: 'vehicleId',
    label: 'Fault:vehicleId',
    options: [],
  },
};

export const faultInputs = {
  description,
  photo,
  vehicleId,
};
