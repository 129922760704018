import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthErrorView } from 'shared-ui/lib/components/auth/AuthErrorView';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { ParseUrl } from 'shared-ui/lib/services/parse-url';
import { routesConfig } from '../../../config/routes.config';
import { useConfirmMutation } from '../../../generated/graphql';

export const ConfirmScene = () => {
  const { t } = useTranslation('ConfirmScene');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [validateToken, { error }] = useConfirmMutation();

  useEffect(() => {
    (async () => {
      try {
        const confirmToken = ParseUrl.parseLocation(location, 'confirmToken');
        await validateToken({
          variables: { body: { confirmToken: confirmToken, type: 'consumer' } },
        });
        setIsLoading(false);
      } catch (e) {
        setShowError(true);
      }
    })();
  }, [location, setShowError, validateToken]);

  useEffect(() => {
    if (error) setShowError(true);
  }, [error]);

  if (showError)
    return <AuthErrorView loginRoute={routesConfig.AUTH.LOGIN} errorReason="Invalid token" />;
  if (isLoading) return <AuthLoadingView />;

  return (
    <AuthLayout>
      <AuthCard>
        <AuthLogoTitle title={t('emailConfirmed')} className="text-primary" />
        <Link
          to={routesConfig.AUTH.LOGIN}
          className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
        >
          {t('backLink')}
        </Link>
      </AuthCard>
    </AuthLayout>
  );
};
